@import '../base/variables';

header {
    display: flex;
    width: 980px;
    padding: 20px 0;
    justify-content: space-between;
    color: #999;

    .brand {
        color: #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;

        &:hover,
        &:active,
        &:visited {
            color: #999;
        }
    }

    h1 {
        font-size: 2em;
        font-family: $secondary-font;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 0.6em;
    }

    nav.menu a {
        padding: 10px 20px;
    }
}

@media screen and (min-width: $break-large) {
    header {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    header {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {
    header {
        padding-bottom: 0;
        padding-top: 0;
        justify-content: center;
        width: $width-small;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {
    header {
        padding-bottom: 0;
        padding-top: 0;
        justify-content: center;
        width: $width-xsmall;
    }
}

@media screen and (max-width: $break-xsmall) {
    header {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        padding-bottom: 0;
        padding-top: 0;
    }
}