@import '../base/variables';

footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #faf9f9;
    color: #999;
    padding: 30px 0 10px 0;

    .social {
        display: flex;

        .brand {
            flex: 4;

            h1 {
                font-size: 2.5em;
                font-family: $secondary-font;
                margin-bottom: 5px;
            }
        }

        .links {
            flex: 1;
            border-left: 1px solid #ccc;

            nav {
                flex-direction: column;

                a {
                    border: none;
                }
            }

            .language-links span:hover {
                cursor: pointer;
            }
        }

        .contact {
            flex: 1;
            border-right: 1px solid #ccc;

            h3 {
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 20px;
            }

            .icons {
                a {

                    &,
                    &:active,
                    &:visited {
                        color: #666;
                    }

                    padding: 5px;

                    i {
                        font-size: 1.3em;
                    }

                    &:hover {
                        cursor: pointer;

                        i {
                            color: #ccc;
                        }
                    }
                }

            }
        }

        .brand,
        .contact,
        .links {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 10px 0;
        }
    }

    .small-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font-size: 0.8em;
        .copyright {
            flex: 1;
            text-align: center;
        }
        .made-by {
            flex: 1;
            margin-left: 10px;
            color: #ccc;
            a, a:hover, a:visited, a:active {
                color: #ccc;
            }
        }
        .filler {
            flex: 1;
        }
    }
}

@media screen and (max-width: $break-medium) {
    footer {
        .social {
            flex-direction: column;

            .brand {
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 20px;
            }

            .contact {
                border-right: 0;
                padding: 0;
            }

            .links {
                border-left: 0;
                padding: 0 10px;
            }
        }

        .small-footer {
            flex-direction: column-reverse;
            .made-by {
                margin-top: 15px;
                text-align: center;
            }
        }
    }
}