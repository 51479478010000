@import '../base/variables';

form {
    width: 100%;
}

input,
textarea {
    display: block;
    padding: 10px;
    width: 100%;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #999;
    margin: 10px;

    &.invalid {
        outline: 1px solid rgb(255, 117, 117);
        background: #f9f9f9;
        border: none;
    }
}

textarea {
    resize: none;
}

.contact-form-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top .title {
        &:after, &:before {
            border: 0;
        }
    }
}

.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 80px 0;
}

.contact-form-background {
    width: 100%;
    height: 100%;
    background-image: url('/images/contact.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .contact-form-background-color {
        background: #fff;
        width: 100%;
        opacity: 0.8;
        height: 100%;
    }
}

.contact-form-details {
    position: relative;
    z-index: 2;
    width: $width-large;
    padding: 10px;
    background: #fff;

    .details {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
    }

    form {
        flex: 1;
        border: 1px solid #999;
        padding: 20px;
        background: #fff;

        .submit-row {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px 0 10px 0;
        }

        p {
            margin-top: 20px;
            small {
                font-size: 12px;
                padding: 10px;
                color: #999;
            }
        }
    }
}

@media screen and (min-width: $break-large) {
    .contact-form-details {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    .contact-form-details {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) {
    .contact-form-details {
        width: 100%;
        .row {
            flex-direction: column;
        }
    }
}