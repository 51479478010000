.youtube-thumbnail {
    min-width: 240px;
    min-height: 180px;

    background-position: center;
    background-size: cover;

    &:hover {
        cursor: pointer;
    }

    .youtube-thumbnail-content {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #999;
        position: relative;

        .thumbnail-background {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(255, 255, 255, 0.8);
            opacity: 0;
        }

        .thumbnail-foreground {
            position: relative;
            opacity: 0;
            z-index: 2;

            h3 {
                font-family: "Darling Modern", cursive;
                font-size: 1.2em;
                text-align: center;
                margin-bottom: 10px;
            }

            p {
                font-size: 0.8em;
                text-align: center;
            }
        }

        &:hover {
            cursor: pointer;

            .thumbnail-background {
                animation: youtubeThumbnailAnimation 0.2s ease-out;
                animation-fill-mode: forwards;
            }

            .thumbnail-foreground {
                animation: youtubeThumbnailAnimation 0.2s ease-out;
                animation-fill-mode: forwards;
            }
        }
    }
}

@keyframes youtubeThumbnailAnimation {
    0% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}