@font-face {
    font-family: 'CormorantGaramond';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/Cormorant_Garamond/CormorantGaramond-Light.ttf')
}

@font-face {
    font-family: 'CormorantGaramond';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf')
}

@font-face {
    font-family: 'CormorantGaramond';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf')
}

@font-face {
    font-family: 'Mrs_Saint_Delafield';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Mrs_Saint_Delafield/MrsSaintDelafield-Regular.ttf')
}

@font-face {
    font-family: 'Darling Modern';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/darlingmodernregular.woff')
}