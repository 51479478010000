@import './variables';

* {
    box-sizing: border-box;
    outline: none;
}

body {
    font-family: $primary-font;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

@media screen and (max-width: $break-small) {
    body {
        font-size: 14px;
    }
}

.page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

img {
    width: 100%;
}

p {
    text-align: justify;
}