$primary-font: 'CormorantGaramond', serif;
$secondary-font: 'Darling Modern', cursive;

$break-large: 1150px;
$break-medium: 960px;
$break-small: 650px;
$break-xsmall: 500px;

$width-large: 980px;
$width-medium: 800px;
$width-small: 600px;
$width-xsmall: 450px;