@import '../base/variables';

$color: #999;
$color2: #aaa;

.behind-the-lens {
    color: $color;
    padding: 40px 0;
    h4 {
        font-family: $primary-font;
        font-size: 1em;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 10px;
        font-size: 0.9em;
    }

    .top {
        margin-bottom: 70px;
    }

    .widget {
        flex: 1 1;
        background: url(/images/behind_the_lens.jpg);
        width: 300px;
        height: 300px;
        min-height: 300px;
        min-width: 300px;
        background-size: cover;
        border-radius: 50%;
    }

    .text {
        flex: 1;
        padding-left: 40px;
    }

    .image {
        flex: 1;
        background-image: url('/images/behind_the_lens.jpg');
        background-position: top;
        background-size: cover;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }
}

@media screen and (min-width: $break-large) {
    .behind-the-lens {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    .behind-the-lens {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {
    .behind-the-lens {
        width: $width-small;

        .row {
            flex-direction: column;
        }

        .text {
            padding-left: 0;
        }

        .widget {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {
    .behind-the-lens {
        width: $width-xsmall;

        .row {
            flex-direction: column;
        }

        .text {
            padding-left: 0;
        }

        .widget {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: $break-xsmall) {
    .behind-the-lens {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;

        .row {
            flex-direction: column;
        }

        .text {
            padding-left: 0;
        }

        .widget {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .behind-the-lens {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}

.title {
    color: $color;
    font-family: $secondary-font;
    font-size: 2.5em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;
    text-transform: lowercase;

    &:before {
        margin-right: 30px;
    }

    &:after {
        margin-left: 30px;
    }

    &:before,
    &:after {
        border: 0.5px solid $color;
        content: '';
        flex: 1;
    }
}

.subtitle {
    color: $color;
    font-family: $primary-font;
    text-align: center;
    font-size: 1em;
    text-transform: uppercase;
}

.row {
    display: flex;
    align-items: center;
}