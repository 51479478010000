.instagram {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 160px 0 50px 0;

    .thumbnail {
        flex: 1;

        margin: 3px;

        &:hover {
            cursor: pointer;
            animation: fadeOut 0.3s ease-out;
            animation-fill-mode: forwards;
        }

        .thumbnail-image {
            min-height: 300px;
            background-position: center;
            background-size: cover;
        }
    }

    .row {
        width: 100%;
        padding: 3px;
    }
}

@media screen and (min-width: $break-large) {
    .instagram {
        .top {
            width: $width-large;
        }
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    .instagram {
        .top {
            width: $width-medium;
        }
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {
    .instagram {
        .top {
            width: $width-small;
        }
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {
    .instagram {
        .top {
            width: $width-xsmall;
        }

        .thumbnail .thumbnail-image {
            min-height: 250px;
        }
    }
}

@media screen and (max-width: $break-xsmall) {
    .instagram {
        .top {
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
        }

        .thumbnail .thumbnail-image {
            min-height: 180px;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .instagram {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}
