button {
    background: #dbb8a7;
    padding: 10px 20px;
    color: #fcfcfc;
    border-radius: 0;
    border: none;
    outline: none;

    &:hover {
        cursor: pointer;
    }

    &.transparent {
        padding: 20px 60px;
        border: 1px solid #999;
        color: #999;
        background: transparent;
    
        &:hover {
            color: #666;
            cursor: pointer;
        }
    }

    &.small {
        padding: 10px 30px;
    }
}
