@import "../base/variables";

.full-width-video {
    width: 100%;

    iframe {
        width: 100%;
        min-height: 743px;
    }
}

.full-width-link {
    margin-top: 60px;
    a {
        padding: 20px 60px;
        border: 1px solid #999;
        color: #999;
        &:hover {
            color: #666;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .full-width-video iframe {
        min-height: 400px;
    }
}