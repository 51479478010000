.youtube-thumbnail-list {
    width: 100%;
    overflow: hidden;
    position: relative;

    .thumbnail-container {
        display: flex;
        position: absolute;
        transition: transform .6s;
    }

    .thumbnail-list-arrow {
        position: absolute;
        bottom: 0;
        z-index: 2;
        font-size: 70px;
        color: #fff;
        opacity: 0.7;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;    
        }

        &.thumbnail-list-left {
            left: 0;
        }

        &.thumbnail-list-right {
            right: 0;
        }
    }
}