@import '../base/variables';

nav.menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    a {
        color: #999;
        padding: 10px 30px;
        flex: 1;
        font-size: 0.9em;
        text-align: center;

        &:not(.brand) {
            text-transform: uppercase;
        }

        &.brand {
            text-align: center;
            flex: 2;
        }

        &.border {
            border-right: 1px solid #999;
        }

        &.firstLink {
            padding-left: 0;
        }

        &.lastLink {
            padding-right: 0;
        }
    }
}

@media screen and (min-width: $break-large) {
    nav.menu.menu-section {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    nav.menu.menu-section {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) {
    nav.menu.menu-section {
        display: none;
    }
}
