.information {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('/images/information-background.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.2;
    }

    .foreground {
        width: 980px;
        position: relative;
        z-index: 2;
        padding: 200px 0;

        .row {
            align-items: normal;
        }

        .info {
            border: 1px solid #ccc;
            flex: 1;
            padding: 10px;

            &:not(:last-of-type) {
                margin-right: 20px;
            }

            &:not(:first-of-type) {
                margin-left: 20px;
            }

            .content {
                border: 1px solid #ccc;
                background: rgba(255, 255, 255, 0.4);
                padding: 20px;
                text-align: center;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                p {
                    margin-bottom: 10px;
                    text-align: center;
                }

                button {
                    margin-top: 20px;
                }
            }

            &:hover {
                cursor: pointer;

                .content {
                    background: rgba(255, 255, 255, 0.7);
                }
            }

            h4 {
                font-size: 1.4em;
                text-align: center;
                margin-bottom: 40px;
                margin-top: 20px;
            }
        }
    }
}