.icon-close {
    color: #666;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: 28px;
    height: 28px;

    &:before {
        content: '';
        position: absolute;
        top: 10px;
        width: 28px;
        height: 3px;
        background-color: currentColor;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &:after {
        content: '';
        position: absolute;
        top: 10px;
        width: 28px;
        height: 3px;
        background-color: currentColor;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.icon-menu {
    color: #000;
    position: absolute;
    margin-left: 2px;
    margin-top: 10px;
    width: 28px;
    height: 3px;
    background-color: currentColor;


    &:before {
        content: '';
        position: absolute;
        top: -8px;
        left: 0;
        width: 28px;
        height: 3px;
        background-color: currentColor;
    }

    &:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 28px;
        height: 3px;
        background-color: currentColor;
    }


}