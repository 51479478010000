.testimonials {
    padding: 100px 0;
    width: 100%;
    color: #999;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .left,
        .right {
            display: flex;
            width: 80px;
            background: rgba(255, 255, 255, 0.7);
            padding: 30px;

            &:hover {
                cursor: pointer;
                background: rgba(255, 255, 255, 0.9);
            }
        }

        .row {
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 400px;
            flex: 1;
        }

        .testimonial {
            width: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;

            &.hidden {
                animation: hide 0.2s forwards;
            }

            &.visible {
                animation: show 1.5s forwards;
            }
        }

        .left {
            margin-right: 30px;
        }

        .right {
            margin-left: 30px;
        }
    }

    h4 {
        font-family: $primary-font;
        font-size: 1em;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 10px;
        font-size: 0.9em;
    }

    .text {
        flex: 1;
        padding-right: 60px;
    }

    .image {
        margin-left: 60px;
        width: 300px;
        height: 300px;

        img {
            max-width: 300px;
            max-height: 300px;
            width: 300px;
        height: 300px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
}

@media screen and (min-width: $break-large) {
    .testimonials {

        .top,
        .row {
            width: $width-large;
            max-width: $width-large;
        }
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    .testimonials {

        .top,
        .row {
            width: $width-medium;
            max-width: $width-medium;
        }
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {
    .testimonials {

        .top,
        .row {
            width: $width-small;
            max-width: $width-small;
        }
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {
    .testimonials {
        .top,
        .row {
            width: $width-xsmall;
            max-width: $width-xsmall;
        }
    }
}

@media screen and (max-width: $break-xsmall) {
    .testimonials {
        .top,
        .row {
            width: 100%;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .testimonials {
        padding-bottom: 50px;
        padding-top: 50px;

        .row {
            padding-left: 20px;
            padding-right: 20px;

            .testimonial {
                flex-direction: column-reverse;

                .text {
                    padding: 0;
                }

                .image {
                    margin-bottom: 40px;
                    margin-left: 0;
                    padding: 0;
                    width: 130px;
                    height: 130px;

                    img {
                        border-radius: 50%;
                        max-width: 130px;
                        max-height: 130px;
                        width: 130px;
                        height: 130px;
                        object-fit: cover;
                    }
                }
            }
        }

        .content {
            .left, .right {
                padding: 10px;
                width: 40px;
            }
        }
    }
}

@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}