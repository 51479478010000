@import '../base/variables';

.mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;

    .toggle-button {
        position: absolute;
        top: 15px;
        right: 40px;
        
        &:hover {
            cursor: pointer;
        }
        
        i {
            color: #333;
            font-size: 2em;
        }

        &.toggle-button-visible {
            i {
                color: #999
            }
        }
    }

    .mobile-menu-content {
        background: #faf9f9;
        width: 100vw;

        .links {
            display: flex;
            flex-direction: column;

            a {
                font-size: 1.8em;
                padding: 10px 20px;

                &,
                &:hover,
                &:active,
                &:visited {
                    color: #999;
                }

                &:last-of-type {
                    padding-bottom: 20px;
                }
            }

            .icons {
                padding: 20px;

                a {
                    margin-right: 10px;
                    padding: 0;
                }
            }
        }

        h1 {
            color: #999;
            font-size: 2em;
            font-family: "Darling Modern", cursive;
            text-align: center;
            padding: 100px 0 70px 0;
        }
    }
}