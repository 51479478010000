@import "../base/variables";

.photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .top {
        width: 980px;
    }

    .youtube-video-wrapper {
        width: 980px;
        margin-bottom: 50px;

        iframe {
            width: 100%;
            min-height: 500px;
        }
    }

    .slides {
        display: grid;
        overflow: auto;
        width: 980px;
    }

    &.photo-list {
        .slides {
            padding: 20px;
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;

            .slide {
                margin: 0;
                min-height: 400px;
            }
        }
    }

    &.photo-categories {
        .slides {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;

            .slide {
                margin: 0;
                min-height: 300px;

                &:hover {

                    .foreground,
                    .background {
                        animation: none;
                    }
                }

                .foreground {
                    opacity: 1;
                }

                .background {
                    opacity: 0.2;
                }
            }
        }
    }

    .slides .slide {
        flex: 1;
        min-width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.images-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 980px;

    img {
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 30px;
        }
    }
}

@media screen and (min-width: $break-large) {
    .photo {

        .images-list,
        .top {
            width: $width-large;
        }
    }

    .photo.photo-list .slides {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    .photo {

        .images-list,
        .top {
            width: $width-medium;
        }
    }

    .photo.photo-list .slides {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {
    .photo {

        .images-list,
        .top {
            width: $width-small;
        }
    }

    .photo.photo-list .slides {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {
    .photo {

        .images-list,
        .top {
            width: $width-xsmall;
        }
    }

    .photo.photo-list .slides {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: $break-xsmall) {
    .photo {
        padding: 10px;

        .images-list,
        .top {
            width: 100%;
        }
    }

    .photo.photo-list .slides {
        grid-template-columns: repeat(1, 1fr);
    }
}