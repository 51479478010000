@import '../base/variables';

.home-header {
    position: relative;
    width: 100%;
    background: linear-gradient(180deg, #ccc, #ccc);

    .background {
        background-image: url("/images/home-header.jpg");
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: 1;
        mix-blend-mode: multiply;
    }

    .foreground {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        position: relative;
        z-index: 2;
        min-height: 100vh;
        text-align: center;
        color: #fcfcfc;

        h1 {
            font-size: 8em;
            font-family: $secondary-font;
        }
    }

    .language-links {
        position: absolute;
        top: 30px;
        right: 40px;

        span:hover {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .home-header .language-links {
        display: none;
    }
}

@media screen and (max-width: $break-small) {
    .home-header .foreground h1 {
        font-size: 3em;
    }
}


.arrowWrapper {
    padding: 16px 9px;
    margin-bottom: 50px;
    position: relative;
    animation: blinking 3s infinite;

    i.arrow {
        border-width: 0 3px 3px 0;
        border-color: #fcfcfc;
        padding: 8px;
        position: absolute;
        left: 0;
        top: 13px;

        &.arrow-first {
            top: 0;
            left: 0;
        }

        &.arrow-second {
            top: 10px;
            left: 0;
        }
    }

}

@keyframes blinking {
    0% {
        opacity: 1;
    }

    20% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}